let $form = document.querySelector('.kontaktformular');
let kontaktformularBestaetigung = document.getElementById('kontaktformular__bestaetigung');
let kontaktformularSchliessen = document.getElementById('kontaktformular__bestaetigung-schliessen');

$form.addEventListener('onAfterFormieSubmit', (e) => {
    e.preventDefault();
    let data = e.detail;
    kontaktformularBestaetigung.setAttribute('data-bestaetigt', true);

    kontaktformularSchliessen.addEventListener('click', function() {
        kontaktformularBestaetigung.setAttribute('data-bestaetigt', false);
    })
});


